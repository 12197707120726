export type NumberDic<K extends string> = { [key: string]: any } & {
  [key in K]: number;
};

type FloatInputProps<C extends string> = {
  name: string;
  coord: C;
  location: NumberDic<C>;
  disabled: boolean;
  setter: (coors: any) => void;
};

function FloatInput<C extends string>({
  name,
  coord,
  location,
  disabled,
  setter,
}: FloatInputProps<C>) {
  function setCoordinate(
    location: NumberDic<typeof coord>,
    coord: string,
    value: number,
    setter: (coors: any) => void
  ) {
    if (!Number.isNaN(value)) {
      const newCoordinates = location;
      location[coord] = value;
      setter(newCoordinates);
    }
  }

  return (
    <input
      type="text"
      name={name}
      onChange={(e) =>
        setCoordinate(location, coord, parseFloat(e.target.value), setter)
      }
      defaultValue={location[coord]}
      onBlur={(e) => (e.target.value = location[coord].toString())}
      disabled={disabled}
    />
  );
}

export default FloatInput;
