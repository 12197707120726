import React from "react";
import styled, { keyframes } from "styled-components";

export type MonopagerItemArguments = {
  open: boolean;
  toggleOpen: () => void;
  data: MonopagerItemData;
};

export type MonopagerItemData = {
  title: string;
  content: () => JSX.Element;
};

const StyledTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  user-select: none;
`;

const StyledArrow = styled.div`
  margin: 0;
  width: 1em;
  height: 100%;
  padding-left: 1em;
  rotate: ${({ open }: { open: boolean }) => (open ? "90" : "0")}deg;
`;

const StyledTitle = styled.h4`
  padding-left: 0.5em;
  margin: 0.5em;
  padding-top: 0;
  padding-bottom: 0;
`;

const hidingContentSection = keyframes`
    from{top:-10px;opacity:0} to{top:0;opacity:1}
`;

const StyledContentSection = styled.div`
  display: ${({ open }: { open: boolean }) => (open ? "block" : "none")};
  list-style-type: none;
  position: relative;
  animation: ${hidingContentSection} 0.4s;
  padding-left: 1.61em;
  margin: 0.5em;
`;

export const StyledRuler = styled.hr`
  margin: 0 1em;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, #ccc, white, #ccc);
`;

export default function MonopagerItem({
  open,
  toggleOpen,
  data,
}: MonopagerItemArguments) {
  return (
    <section>
      <StyledTitleDiv onClick={toggleOpen}>
        <StyledArrow open={open}>▶</StyledArrow>
        <StyledTitle>{data.title}</StyledTitle>
      </StyledTitleDiv>
      <StyledRuler />
      <StyledContentSection open={open}>{data.content()}</StyledContentSection>
    </section>
  );
}
