import React from "react";
import Header from "../../components/header/Header";
import Monopager from "../../components/monopager/Monopager";
import { routes } from "../../routes";
import { getHostName } from "../../utils";

function GD_content() {
  const desiredLinks = [
    "Results handling",
    "Energy estimation",
    "Finite elements method",
    "Variational Quantum Algorithms",
  ];
  let links = new Map<string, string>();
  routes.forEach((route) => {
    if (route.linkName && desiredLinks.includes(route.linkName)) {
      links.set(route.linkName, getHostName() + route.path);
    }
  });

  return (
    <div>
      {desiredLinks.map((key) => {
        return (
          <li key={key}>
            <a href={links.get(key)}>{key}</a>
          </li>
        );
      })}
    </div>
  );
}

function T_content() {
  const desiredLinks = [
    "Hypoelastic 1D deformation",
    "Elastic 2D deformation",
    "Others",
  ];
  let links = new Map<string, string>();
  routes.forEach((route) => {
    if (route.linkName && desiredLinks.includes(route.linkName)) {
      links.set(route.linkName, getHostName() + route.path);
    }
  });

  return (
    <div>
      {desiredLinks.map((key) => {
        return (
          <li key={key}>
            <a href={links.get(key)}>{key}</a>
          </li>
        );
      })}
    </div>
  );
}

function FAQ_content() {
  const desiredLinks = [
    "How do I create a new project?",
    "Can I export the solution?",
    "Can I import my own model?",
    "Can I choose the algorithm to use?",
    "Can I estimate the cost of my simulation in advance?",
  ];
  let links = new Map<string, string>();
  routes.forEach((route) => {
    if (route.linkName && desiredLinks.includes(route.linkName)) {
      links.set(route.linkName, getHostName() + route.path);
    }
  });

  return (
    <div>
      {desiredLinks.map((key) => {
        return (
          <li key={key}>
            <a href={links.get(key)}>{key}</a>
          </li>
        );
      })}
    </div>
  );
}

function S_content() {
  const desiredLinks = ["Contact form", "Report a bug"];
  let links = new Map<string, string>();
  routes.forEach((route) => {
    if (route.linkName && desiredLinks.includes(route.linkName)) {
      links.set(route.linkName, getHostName() + route.path);
    }
  });

  return (
    <div>
      {desiredLinks.map((key) => {
        return (
          <li key={key}>
            <a href={links.get(key)}>{key}</a>
          </li>
        );
      })}
    </div>
  );
}

const sections = [
  { title: "General documentation", content: GD_content },
  { title: "Tutorials", content: T_content },
  { title: "FAQ", content: FAQ_content },
  { title: "Support", content: S_content },
];

export default function Help() {
  //     const [clicked, setClicked] = useState(false)
  return (
    <React.Fragment>
      <Header />
      {/* <Container> */}
      {/* <Center> */}
      <Monopager page_title="Help" items={sections} />
      {/* </Center> */}
      {/* <SideRight>
                    <h4>Software Tour</h4>
                    <StyledRuler/>
                    <button onClick={() => setClicked(!clicked)}>{clicked? "End tour" : "Start tour"}</button>
                </SideRight>
             </Container> */}
    </React.Fragment>
  );
}
