import { BoundaryCondition } from "../../../api";
import { emplacements } from "./MaterialDeformation";
import * as Styled from "./style";
import Selector from "../../../components/Selector";
import { BiTrash } from "react-icons/bi";

type BCSettingArguments = {
  bConds: BoundaryCondition[];
  index: number;
  setBConds: (bConds: BoundaryCondition[]) => void;
  disabled: boolean;
};

export function BCSetting({
  bConds,
  index,
  setBConds,
  disabled = false,
}: BCSettingArguments) {
  function removeBCond() {
    setBConds([...bConds.slice(0, index), ...bConds.slice(index + 1)]);
  }

  function setEmplacement(emplacement: string) {
    const newBCond = { ...bConds[index], emplacement: emplacement };
    setBConds([
      ...bConds.slice(0, index),
      newBCond,
      ...bConds.slice(index + 1),
    ]);
  }

  return (
    <>
      <h3 style={{ fontSize: "0.9rem" }}>
        Boundary Condition {index + 1}
        {!disabled && (
          <Styled.Button
            title="Remove boundary condition"
            style={{ fontSize: "1.1rem" }}
            onClick={removeBCond}
            disabled={disabled}
          >
            <BiTrash />
          </Styled.Button>
        )}
      </h3>
      <Styled.SideInput>
        <Styled.Label htmlFor="BC emplacement">BC emplacement</Styled.Label>
        <Selector
          name="BC emplacement"
          currentValue={bConds[index].emplacement}
          setCurrentValue={setEmplacement}
          availableValues={emplacements ? emplacements : []}
          defaultValue={"Select emplacement"}
          removeDefault={true}
          disabled={disabled}
        />
      </Styled.SideInput>
    </>
  );
}
