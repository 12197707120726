import React from "react";
import styled from "styled-components";

const lenght_unit = "px";
const default_dimentions = { size: 35, thickness: 5, spacing: 6 };

type BurgerButtonArguments = {
  open: boolean;
  onClick?: () => void;
  dimentions?: ButtonDimentions;
};

type StyledButtonArguments = {
  open: boolean;
  dimentions: ButtonDimentions;
};

type ButtonDimentions = {
  size: number;
  thickness: number;
  spacing: number;
};

const StyledButton = styled.button`
  float: right;
  position: relative;
  top: 50%;
  translate: 0 -50%;
  width: auto;
  height: ${({ dimentions }: StyledButtonArguments) => 6 * dimentions.thickness}${lenght_unit};
  padding: 0 20px;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;

  span {
    width: ${({ dimentions }: StyledButtonArguments) => dimentions.size}${lenght_unit};
    height: ${({ dimentions }: StyledButtonArguments) => dimentions.thickness}${lenght_unit};
    background: black;
    border-radius: ${({ dimentions }: StyledButtonArguments) =>
        dimentions.thickness / 2}${lenght_unit};
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :nth-child(1) {
      transform: ${({ open }: StyledButtonArguments) =>
        open ? "rotate(45deg)" : "rotate(0)"};
    }
    :nth-child(2) {
      opacity: ${({ open }: StyledButtonArguments) => (open ? "0" : "1")};
      transform: ${({ open }: StyledButtonArguments) =>
        open ? "translateX(20px)" : "translateX(0)"};
    }
    :nth-child(3) {
      transform: ${({ open }: StyledButtonArguments) =>
        open ? "rotate(-45deg)" : "rotate(0)"};
    }
  }
`;

/**
 * The burger button which appears on the top right.
 * @param Arguments is a set of three parameters {open, onClick, dimensions}: 
 * - open is a boolean that tells if the menu associated with the button is open, 
 * - onClick specifies the action to complete when we click on the button, 
 * - dimensions specifies the dimension of the button.
 */
export default function BurgerButton({
  open,
  onClick,
  dimentions = default_dimentions,
}: BurgerButtonArguments) {
  return (
    <div>
      <StyledButton
        onClick={() => {
          onClick && onClick();
        }}
        dimentions={dimentions}
        open={open}
      >
        <span />
        <span />
        <span />
      </StyledButton>
    </div>
  );
}
