import todo_image from "./icons8-constructing-100.png";
import React from "react";
import styled from "styled-components";

const StyledWip = styled.div`
  text-align: center;
  margin-top: 10%;
`;

function Todo() {
  return (
    <StyledWip>
      <img src={todo_image} alt="Under construction" />
      <p>Under development</p>
    </StyledWip>
  );
}

export default Todo;
