import { useState } from "react";
import styled, { keyframes } from "styled-components";
import { signOut } from "../../../pages/login";
import { routes } from "../../../routes";
import { getHostName } from "../../../utils";

const LinkButton = styled.button`
  font-family: "Verdana" sans-serif;
  font-size: 1em;
  text-align: left;
  color: blue;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
`;

const StyledItem = styled.li`
  padding-top: 1em;
  margin: 1em;
  text-align: left;
`;

const BottomItem = styled(StyledItem)`
  position: fixed;
  bottom: 1.61em;
`;

const sideBarAnimation = keyframes`
    0% {right:-300px; opacity:0} 
    100% {right:0; opacity:1}
`;

const StyledSideBar = styled.ul`
  display: ${({ visible }: { visible: boolean }) =>
    visible ? "block" : "none"};
  right: 0;
  bottom: -30px;
  top: 86px;
  margin-block-start: 0;
  margin-block-end: 0;
  list-style-type: none;
  height: calc(100vh - 5.5rem);
  width: 200px;
  background-color: white;
  position: fixed !important;
  z-index: 1;
  overflow: auto;
  box-shadow: -5px 0 5px -3px rgba(0, 0, 0, 0.16),
    -5px 0 5px -3px rgba(0, 0, 0, 0.12);
  position: relative;
  animation-name: ${sideBarAnimation};
  animation-duration: 0.4s;
`;

/**
 *
 * This creates the Right navigation menu that is open when one clicks on the Burger Button.
 * @param props
 * @param props.visible visible is a boolean that tells whether the menu is visible or not.
 */
export default function RightNav({ visible }: { visible: boolean }) {
  const desiredLinks = ["Profile", "Jobs", "Help"];
  const [isConnectedValue, setIsConnectedValue] = useState(true);

  let links = new Map<string, string>();
  routes.forEach((route) => {
    if (route.linkName && desiredLinks.includes(route.linkName)) {
      links.set(route.linkName, getHostName() + route.path);
    }
  });

  return (
    <StyledSideBar visible={visible}>
      <>{!isConnectedValue ? (window.location.href = "/login") : null}</>

      {desiredLinks.map((key) => {
        return (
          <StyledItem key={key}>
            <a href={links.get(key)}>{key}</a>
          </StyledItem>
        );
      })}
      <StyledItem>
        <LinkButton
          onClick={() => {
            signOut().then(() => setIsConnectedValue(false));
          }}
        >
          Disconnect
        </LinkButton>
      </StyledItem>

      <BottomItem>
        <a href={window.location.href + "#"}>Contact us</a>
      </BottomItem>
    </StyledSideBar>
  );
}
