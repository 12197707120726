import React, { useEffect, useState } from "react";
import { HiCheckCircle, HiClock } from "react-icons/hi";
import { IoCloseCircle } from "react-icons/io5";
import styled from "styled-components";
import { GeneralApi } from "../../api";
import Selector from "../../components/Selector";
import Header from "../../components/header/Header";
import {
  StyledContainer,
  StyledContent,
  StyledTitle,
} from "../../components/monopager/Monopager";
import { StyledRuler } from "../../components/monopager/item/MonopagerItem";

const StyledNav = styled.nav`
  width: max(200px, 10%);
  color: white;
  background-color: dimgrey;
  box-shadow: 5px 0 5px -3px rgba(0, 0, 0, 0.16),
    5px 0 5px -3px rgba(0, 0, 0, 0.12);
`;

const StyledCustomContainer = styled(StyledContainer)`
  min-height: calc(100vh - 5.5rem);
  height: fit-content;
`;

const generalApi = new GeneralApi();

enum Status {
  Completed = "Completed",
  Ongoing = "Ongoing",
  Cancelled = "Cancelled",
}

type Job = {
  id: string;
  name: string;
  jobType: string;
  dateStarted: string;
  price: number;
  status: Status;
};

const currency = "€";

// lists used for adding random fake elements for non-known job attributes
const nameList = [
  "Steel Beam",
  "Wood Ellipse",
  "Iron Axe",
  "Silver Plate",
  "Glass Cylinder",
  "Gold Ring",
];

function statusIcon(status: Status) {
  switch (status) {
    case Status.Completed:
      return <HiCheckCircle color="green" />;
    case Status.Ongoing:
      return <HiClock color="yellow" />;
    case Status.Cancelled:
      return <IoCloseCircle color="red" />;
    default:
      return "Unimplemented";
  }
}

function navigateToMD(jobId: string, action: string) {
  window.location.replace(
    "/material_deformation/" + JSON.stringify({ id: jobId, action: action })
  );
}

export default function Jobs({ offlineCall }: { offlineCall?: boolean }) {
  /** Define the component that renders the Jobs page */

  useEffect(() => {
    getJobs();
  }, []);

  const [jobs, setJobs] = useState<Job[]>([]);
  let idToken: any;
  for (const [key, value] of Object.entries(localStorage)) {
    if (key?.includes("idToken")) {
      idToken = value;
    }
  }
  function getJobs(offlineCall?: boolean) {
    if (offlineCall) getJobsNoBackend();
    else
      generalApi.getJobs(undefined, idToken).then(async (res) => {
        setJobs(
          await Promise.all(
            res.data.map(async (jobId) => {
              return await generalApi.getJob(jobId, idToken).then((res) => {
                const job = res.data;
                return {
                  id: jobId,
                  name: nameList[Math.floor(Math.random() * nameList.length)],
                  jobType: job.type,
                  status:
                    job.progress === 100
                      ? Status.Completed
                      : job.end_date
                      ? Status.Cancelled
                      : Status.Ongoing,
                  dateStarted: job.start_date
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("/"),
                  price: Math.floor(Math.random() * 1000),
                };
              });
            })
          )
        );
      });
  }

  function getJobsNoBackend() {
    setJobs([
      {
        id: "j-1",
        name: "Silver Plate",
        jobType: "md",
        status: Status.Ongoing,
        dateStarted: "14/11/2022",
        price: 522,
      },
      {
        id: "j-2",
        name: "Gold Ring",
        jobType: "md",
        status: Status.Completed,
        dateStarted: "14/11/2022",
        price: 158,
      },
      {
        id: "j-3",
        name: "Silver Plate",
        jobType: "cfd",
        status: Status.Cancelled,
        dateStarted: "14/11/2022",
        price: 556,
      },
      {
        id: "j-4",
        name: "Iron Axe",
        jobType: "cfd",
        status: Status.Completed,
        dateStarted: "14/11/2022",
        price: 357,
      },
      {
        id: "j-5",
        name: "Gold Ring",
        jobType: "md",
        status: Status.Completed,
        dateStarted: "14/11/2022",
        price: 544,
      },
      {
        id: "j-6",
        name: "Silver Plate",
        jobType: "md",
        status: Status.Completed,
        dateStarted: "14/11/2022",
        price: 495,
      },
      {
        id: "j-7",
        name: "Silver Plate",
        jobType: "md",
        status: Status.Completed,
        dateStarted: "14/11/2022",
        price: 411,
      },
      {
        id: "j-8",
        name: "Wood Ellipse",
        jobType: "md",
        status: Status.Completed,
        dateStarted: "14/11/2022",
        price: 893,
      },
      {
        id: "j-9",
        name: "Iron Axe",
        jobType: "md",
        status: Status.Ongoing,
        dateStarted: "14/11/2022",
        price: 85,
      },
      {
        id: "j-10",
        name: "Gold Ring",
        jobType: "md",
        status: Status.Ongoing,
        dateStarted: "14/11/2022",
        price: 196,
      },
      {
        id: "j-11",
        name: "Gold Ring",
        jobType: "md",
        status: Status.Ongoing,
        dateStarted: "14/11/2022",
        price: 619,
      },
      {
        id: "j-12",
        name: "Gold Ring",
        jobType: "md",
        status: Status.Completed,
        dateStarted: "14/11/2022",
        price: 806,
      },
      {
        id: "j-13",
        name: "Glass Cylinder",
        jobType: "md",
        status: Status.Ongoing,
        dateStarted: "05/12/2022",
        price: 15,
      },
      {
        id: "j-14",
        name: "Glass Cylinder",
        jobType: "md",
        status: Status.Completed,
        dateStarted: "06/12/2022",
        price: 684,
      },
      {
        id: "j-15",
        name: "Glass Cylinder",
        jobType: "md",
        status: Status.Completed,
        dateStarted: "06/12/2022",
        price: 867,
      },
      {
        id: "j-16",
        name: "Wood Ellipse",
        jobType: "md",
        status: Status.Completed,
        dateStarted: "06/12/2022",
        price: 697,
      },
      {
        id: "j-17",
        name: "Gold Ring",
        jobType: "md",
        status: Status.Completed,
        dateStarted: "06/12/2022",
        price: 115,
      },
    ]);
  }

  const [currentFilter, setFilter] = useState<string>("All");

  return (
    <React.Fragment>
      <Header />
      <StyledCustomContainer>
        <StyledNav>
          <StyledTitle> Jobs </StyledTitle>
          <StyledRuler />
          Filter results
          <Selector
            name="Filter"
            currentValue={currentFilter}
            setCurrentValue={(filter) => setFilter(filter)}
            availableValues={["All"].concat(Object.keys(Status))}
            showDefault={false}
          />
        </StyledNav>
        <StyledContent>
          <div>
            <table cellPadding="4" cellSpacing="4">
              <tbody>
                <tr>
                  <th> Type </th>
                  <th> Status </th>
                  <th> Name </th>
                  <th> Job ID </th>
                  <th> Date Started </th>
                  <th> Price </th>
                  <th> Action </th>
                </tr>

                {jobs
                  .filter(
                    (job) =>
                      currentFilter === "All" || job.status === currentFilter
                  )
                  .map((job) => (
                    <tr key={job.id}>
                      <td>{job.jobType}</td>
                      <td>
                        {statusIcon(job.status)} {job.status}
                      </td>
                      <td>{job.name}</td>
                      <td>{job.id}</td>
                      <td>{job.dateStarted}</td>
                      <td>
                        {job.status !== Status.Ongoing && job.price + currency}
                      </td>
                      <td>
                        {job.status === Status.Ongoing && (
                          <button
                            onClick={() => {
                              generalApi.cancelJob(job.id, idToken);
                              getJobs();
                            }}
                          >
                            Cancel
                          </button>
                        )}
                        {job.status === Status.Completed && (
                          <button onClick={() => navigateToMD(job.id, "read")}>
                            View results
                          </button>
                        )}
                        {job.status === Status.Cancelled && (
                          <button onClick={() => navigateToMD(job.id, "run")}>
                            Relaunch
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </StyledContent>
      </StyledCustomContainer>
    </React.Fragment>
  );
}
