import React from "react";
import Header from "../../components/header/Header";
import Monopager from "../../components/monopager/Monopager";

const setting_sections = [
  { title: "General", content: () => <div>Hello</div> },
  { title: "Accessibility", content: () => <div>Hello</div> },
  { title: "Billing method", content: () => <div>Hello</div> },
  { title: "Notifications", content: () => <div>Hello</div> },
  { title: "Confidentiality", content: () => <div>Hello</div> },
  { title: "Advanced", content: () => <div>Hello Advanced</div> },
];

function Profil() {
  return (
    <React.Fragment>
      <Header />
      <Monopager page_title="Profile" items={setting_sections} />
    </React.Fragment>
  );
}

export default Profil;
