import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./routes.tsx";
import { routes } from "./routes";
import TitleSetter from "./components/TitleSetter";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route
            path={route.path}
            element={
              <TitleSetter title={route.title} element={route.element} />
            }
            key={route.title.replace(" ", "_")}
          />
        ))}
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
