import { emplacements } from "./MaterialDeformation";
import * as Styled from "./style";
import { Load, Coordinates } from "../../../api";
import Selector from "../../../components/Selector";
import { BiTrash } from "react-icons/bi";

type LoadSettingArguments = {
  loads: Load[];
  index: number;
  setLoads: (loads: Load[]) => void;
  disabled: boolean;
};

export function LoadSetting({
  loads,
  index,
  setLoads,
  disabled = false,
}: LoadSettingArguments) {
  function removeLoad() {
    setLoads([...loads.slice(0, index), ...loads.slice(index + 1)]);
  }

  function setLoadValue(valueName: keyof Load, value: any) {
    if (typeof value === typeof loads[index][valueName]) {
      const newLoad = { ...loads[index], [valueName]: value };
      setLoads([...loads.slice(0, index), newLoad, ...loads.slice(index + 1)]);
    }
  }

  return (
    <>
      <h3>
        Load {index + 1}
        {!disabled && (
          <Styled.RemoveParameterButton
            title="Remove load"
            onClick={removeLoad}
            disabled={disabled}
          >
            <BiTrash />
          </Styled.RemoveParameterButton>
        )}
      </h3>
      <Styled.SideInput>
        <Styled.Label htmlFor="Load emplacement">Load emplacement</Styled.Label>
        <Selector
          name="Load emplacement"
          currentValue={loads[index].emplacement}
          setCurrentValue={(emplacement) =>
            setLoadValue("emplacement", emplacement)
          }
          availableValues={emplacements ? emplacements : []}
          defaultValue={"Select emplacement"}
          removeDefault={true}
          disabled={disabled}
        />
      </Styled.SideInput>
      <Styled.Label>Load direction</Styled.Label>
      {Object.keys(loads[index].direction).map((coord) => {
        return (
          <Styled.LoadDirectionDiv key={coord}>
            <Styled.LoadDirectionlabel htmlFor={coord}>
              {coord}:{" "}
            </Styled.LoadDirectionlabel>
            <Styled.FInput
              name={coord}
              location={
                loads[index].direction as { [x in keyof Coordinates]: number }
              }
              coord={coord}
              disabled={disabled}
              setter={(coords) => setLoadValue("direction", coords)}
            />
          </Styled.LoadDirectionDiv>
        );
      })}

      <Styled.SideInput>
        <Styled.Label htmlFor="Load intensity">Load intensity</Styled.Label>
        <Styled.FInput
          name="Load intensity"
          location={loads[index] as unknown & { intensity: number }}
          coord={"intensity"}
          setter={(val) => setLoadValue("intensity", val)}
          disabled={disabled}
        />
      </Styled.SideInput>
    </>
  );
}
