import { MdDriveFileRenameOutline } from "react-icons/md";
import styled from "styled-components";
import FloatInput from "../../../components/FloatInput";

type InputArgument = {
  disabled?: boolean;
};

export const CustomMaterial = styled.div`
  display: ${({ visible }: { visible: boolean }) =>
    visible ? "block" : "none"};
`;

export const Input = styled.input`
  box-shadow: 0.1rem 0.1rem black;
  border-radius: 0.25rem;
  padding: 0.1rem;
  font-size: 0.9rem;
  max-width: 70%;
  cursor: ${({ disabled = false }: InputArgument) =>
    disabled ? "not-allowed" : "default"};
`;

export const FInput = styled(FloatInput)`
  box-shadow: 0.1rem 0.1rem black;
  border-radius: 0.25rem;
  padding: 0.1rem;
  font-size: 0.9rem;
  max-width: 70%;
  cursor: ${({ disabled = false }: InputArgument) =>
    disabled ? "not-allowed" : "default"};
`;

export const SideInput = styled.div`
  margin: 0.5rem;

  input,
  select {
    margin: 0.5rem;
  }
`;

export const MultipleParameterDiv = styled.div`
  border: 2.5px solid #5f5f5f;
`;

export const Header = styled.h3`
  filter: drop-shadow(1px 1px 1px black);
  font-weight: bold;
  font-size: 1.4rem;
  padding: 1rem 0 0 0;
  border-color: white;
  border-style: solid;
  border-width: 1px 0 0 0;
`;

export const SubHeader = styled(Header)`
  border-width: 0;
  margin: 0rem 1rem 1rem 1rem;
`;

export const Label = styled.label`
  filter: drop-shadow(0.5px 1px 1px black);
  font-weight: "400";
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 5.5rem);
`;

export const Side = styled.div`
  flex: 0.1;
  filter: drop-shadow(1px 1px 1px black);
  color: white;
  background-color: #4f4f4f;
  padding: 0.5rem;
  text-align: center;
  position: relative;
`;

export const Center = styled.div`
  flex: 0.8;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const ResultImage = styled.img`
  max-width: 100%;
  margin: auto;
  padding: 1em;

  @media (min-width: 1080px) {
    max-width: 60%;
  }
`;

export const ModalBackground = styled.div`
  display: ${({ hide }: { hide: boolean }) => (hide ? "none" : "block")};
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContent = styled.div`
  display: ${({ hide }: { hide: boolean }) => (hide ? "none" : "block")};
  background-color: #fefefe;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  width: max-content;
  border-radius: 0.5em;
`;

export const Close = styled.span`
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  :hover,
  :focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const Project = styled.div`
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  border-width: 1px 0 0 0;
  vertical-align: middle;
  cursor: pointer;
`;

export const Button = styled.button`
  background-color: transparent;
  border-width: 0;
  color: white;
  outline: none;
  font-size: 2.1rem;
  filter: drop-shadow(2px 2px 2px black);
  transition: all 0.1s ease-in;
  vertical-align: middle;

  &:hover {
    transform: translateY(-1px);
    transform: ${({ disabled = false }: InputArgument) =>
      disabled ? "scale(1)" : "scale(1.15)"};
    /* font-size: 2.2rem; */
  }
  cursor: ${({ disabled = false }: InputArgument) =>
    disabled ? "not-allowed" : "pointer"};
`;

export const BlackButton = styled(Button)`
  color: black;
`;

export const RunButton = styled(Button)`
  font-size: 2.5rem;
  margin-right: -0.8rem;
`;

export const RenameButton = styled(Button)`
  font-size: 1.2rem;
  position: relative;
`;

export const RenameIcon = styled(MdDriveFileRenameOutline)`
  padding-bottom: -20rem;
`;

export const UnnamedProject = styled.span`
  font-style: italic;
`;

export const ProjectName = styled.div`
  font-size: 1.2rem;
  padding-top: 0.5rem;
`;

export const LoadDirectionDiv = styled.div`
  padding: 0.2rem;
`;

export const LoadDirectionlabel = styled.label`
  font-weight: 600;
`;

export const RemoveParameterButton = styled(Button)`
  font-size: "1.1rem";
`;

export const ProjectChoice = styled.h3`
  display: flex;

  button {
    color: black;
  }
`;
