import {
  MdOptions,
  MdSolveData,
  Load,
  BoundaryCondition,
  Project,
  ProjectIds,
  MdResult,
  CustomMaterial,
} from "../../../api";

export const load: Load = {
  id: "1",
  emplacement: "",
  direction: {
    x: 0,
    y: 0,
    z: 0,
  },
  intensity: 0,
};

export const bCond: BoundaryCondition = {
  id: "1",
  emplacement: "",
};

export const customMaterial: CustomMaterial = {
  ProportionalStressLimit: 0,
  ProportionalStrainLimit: 0,
  BulkModulus: 0,
  nPowerLaw: 0,
};

export const solveData: MdSolveData = {
  shape: "",
  material: "",
  noiseModel: "",
  algorithm: "",
  loads: [load],
  boundaryConditions: [bCond],
};

export const projectIds: ProjectIds = {
  id: "none",
  name: "",
};

export const project: Project = {
  ...projectIds,
  parameters: solveData,
};

export const options: MdOptions = {
  shapes: [],
  materials: [],
  noiseModels: [],
  algorithms: [],
};

export const mdResult: MdResult = {
  stress: new File([], ""),
  displacement: new File([], ""),
};
