import React from "react";
import Header from "../../../components/header/Header";
import Todo from "../../../components/todo/Todo";

// TODO-WIP : replace <TODO /> by the true content of the page.

function Robotics() {
  return (
    <React.Fragment>
      <Header />
      <Todo />
    </React.Fragment>
  );
}

export default Robotics;
