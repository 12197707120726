import styled, { keyframes } from "styled-components";

const raiseApparition = keyframes`
   from{top:1.61em; opacity:0} to{top:0; opacity:1}
`;

const StyledGrid = styled.article`
  display: grid;
  grid-template-columns: repeat(1, minmax(200px, 1fr));
  column-gap: 1em;
  row-gap: 1em;
  margin: 2em;
  position: relative;
  animation: ${raiseApparition} 0.8s;
  top: 5%;
  height: calc(100vh - 10rem);
  width: 95vw;

  @media (min-width: 500px) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  @media (min-width: 700px) {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(4, minmax(200px, 1fr));
  }
`;

function Showcase({ use_case_cards }: { use_case_cards: JSX.Element[] }) {
  return <StyledGrid>{use_case_cards}</StyledGrid>;
}

export default Showcase;
