import styled from "styled-components";
import { getHostName } from "../../utils";
import default_image from "./icons8-image.svg";

const StyledCard = styled.a`
  text-align: center;
  transition: transform 0.2s;

  :hover {
    transform: scale(1.2);
  }
`;

const StyledUCImage = styled.img`
  width: 200px;
  height: 130px;
  border-radius: 12px;
  background-color: black;
  box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.16),
    5px 5px 5px -3px rgba(0, 0, 0, 0.12);
`;

const StyledUCName = styled.h4`
  margin: 0;
  position: relative;
  color: white;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.16), 5px 5px 5px rgba(0, 0, 0, 0.12);
`;

/**
 * 
 * @category Components
 * This function generates the use-case card that appears in the homepage of the website.
 * @param props
 * @param props.title title is a 'string' containing the title of the use case associated with the card.
 * @param props.image_source image_source is a 'string' containing the file name of the use-case representative image.
 * 
 */
export default function UseCaseCard({
  title,
  image_source,
}: {
  title: string;
  image_source?: string;
}) {
  const page_name = title.toLowerCase().replace(" ", "_");
  const link = getHostName() + "/" + page_name;

  return (
    <StyledCard href={link} key={"use_case_key_" + page_name}>
      <StyledUCImage
        src={image_source ? image_source : default_image}
        alt={title + " illustration"}
      />
      <StyledUCName className="use-case-name">{title}</StyledUCName>
    </StyledCard>
  );
}
