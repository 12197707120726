import { Suspense } from "react";
import LoginPage, { isConnected } from "../pages/login";

export type TitleSetterProps = { title: string; element: () => JSX.Element };

function fetchConnectionStatus() {
  let status = "pending";
  let result: Error | boolean;
  let suspender = isConnected().then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") throw suspender;
      else if (status === "error") throw result;
      else if (status === "success") return result;
    },
  };
}

const connectionStatusFetch = fetchConnectionStatus();

export default function TitleSetter({ title, element }: TitleSetterProps) {
  document.title = title;

  return (
    <Suspense fallback={<>Checking connection status...</>}>
      <ConnectionChecker component={element} />
    </Suspense>
  );
}

function ConnectionChecker({ component }: { component: () => JSX.Element }) {
  const connectionStatus = connectionStatusFetch.read();
  const displayComponent = connectionStatus || component === LoginPage;
  if (!displayComponent) window.location.replace("/login");
  // TODO: useNavigate or something similar would probably be better because you
  // have a glimpse of the page before it fully loads. In fact, this kind of
  // routing should probably be done before this point... But for now, calling a
  // hook here causes a hook order error, so I'll live this for now

  return component();
}
