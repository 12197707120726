import { useState } from "react";
import styled from "styled-components";

export type SelectorArguments<T> = {
  currentValue: T;
  setCurrentValue: (value: T) => void;
  availableValues: T[];
  defaultValue?: T;
  removeDefault?: boolean;
  name?: string;
  disabled?: boolean;
  showDefault?: boolean;
};

type StyledSelectArguments = {
  disabled: boolean;
};

const StyledSelect = styled.select`
  width: 10rem;
  height: 1.8rem;
  border: 0.1rem solid #999;
  font-size: 1rem;
  color: black;
  background-color: #eee;
  border-radius: 0.5rem;
  box-shadow: 0.15rem 0.15rem black;
  cursor: ${({ disabled }: StyledSelectArguments) =>
    disabled ? "not-allowed" : "default"};
`;

/**
 * 
 * @param props
 * 
 */
export default function Selector({
  currentValue,
  setCurrentValue,
  availableValues,
  defaultValue,
  removeDefault,
  name,
  disabled = false,
  showDefault = true,
}: SelectorArguments<string>) {
  const [valueChanged, setValueChanged] = useState(false);

  return (
    <StyledSelect
      id={name}
      name={name}
      value={currentValue}
      onChange={(e) => {
        setValueChanged(true);
        setCurrentValue(e.target.value);
      }}
      className="jh-categories-select"
      disabled={disabled}
    >
      {!(removeDefault && valueChanged) && showDefault && (
        <option value="">{defaultValue ? defaultValue : "---"}</option>
      )}
      {availableValues.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </StyledSelect>
  );
}
