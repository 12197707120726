import React, { useState } from "react";
import ReactJoyride, { Step } from "react-joyride";
import * as Styled from "./style";

type JoyrideType = {
  run: boolean;
  steps: Step[];
};

const OnboardingSteps = () => {
  const [joyride, setJoyride] = useState<JoyrideType>({
    run: false,
    steps: [
      {
        target: "#Materials",
        content:
          'Scroll the list to choose a predefined material or select "custom" to define a new one.',
      },
      {
        target: "#Load",
        content:
          "Here you can set the loads of the system. You can specify their position, direction and intensity.",
      },
      {
        target: "#BC",
        content:
          "Here you can set the boundary conditions of the differential equations describing the system.",
      },
      {
        target: "#Noise_and_simulations",
        content: "Choose whether to run noisy or ideal calculations.",
      },
      {
        target: "#Project",
        content:
          "At any moment you can run or save your current project, load or create a new one.",
      },
      {
        target: "#Tour",
        content: "Click here to take the tour any time you want.",
      },
    ],
  });

  return (
    <React.Fragment>
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <Styled.Button
          onClick={() => {
            setJoyride({ ...joyride, run: !joyride.run });
          }}
          style={{
            backgroundColor: "#000000",
            color: "white",
            border: "none",
            fontSize: "1.1rem",
            fontWeight: "bolder",
            padding: "10px 10px",
            cursor: "pointer",
            borderRadius: "10px",
          }}
        >
          Take the tour
        </Styled.Button>
      </div>
      <ReactJoyride
        styles={{
          buttonNext: {
            background: "#940000",
          },
          buttonSkip: {
            color: "black",
            fontStyle: "italic",
          },
          options: {
            backgroundColor: "white",
            textColor: "black",
            primaryColor: "#6b0000",
          },
        }}
        steps={joyride.steps}
        run={joyride.run}
        continuous
        showProgress
        showSkipButton
      />
    </React.Fragment>
  );
};

export default OnboardingSteps;
